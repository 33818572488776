<template>
  <div>
    <!-- client avatar -->
    <img
      class="rw-chat-img"
      :src="clientImage"
      :title="message.name"
      alt="rw"
    />

    <!-- client message -->
    <div class="rw-chat-text d-flex flex-col flex-wrap">
      <div class="break-all">
        <!--for text message -->
        <div
          v-html="nl2br(message.message)"
          v-if="
            ['text'].includes(message.tipe) ||
            (['image', 'file'].includes(message.tipe) &&
              (message.deleted === '1' || message.deleted === true))
          "
        ></div>

        <!--for image-->
        <div v-if="message.tipe === 'image' && message.deleted === '0'">
          <a :href="mixGetImage(message.message)" target="_blank"
            ><img
              :src="mixGetImage(message.message)"
              :alt="message.message"
              style="height: 78px; max-width: 100%"
          /></a>
        </div>

        <!--for attachment-->
        <div
          v-if="message.tipe === 'file' && message.deleted === '0'"
          class="d-flex align-items-end"
        >
          <svg class="qc-icon mt-0">
            <use xlink:href="#rw-file-download"></use>
          </svg>
          <a :href="mixGetFiles(message.message)" target="_blank">Download</a>
        </div>
      </div>

      <div class="flex content-center items-center self-end absolute bottom-0">
        <!-- terkirim -->
        <template v-if="message.status === 'sent'">
          <small> {{ mixEpochToHours(message.time) }} </small>&nbsp;
          <span class="material-symbols-outlined text-sm text-success"
            >done_all</span
          >
        </template>

        <!-- pending -->
        <svg class="rw-spin icon" v-if="message.status === 'pending'">
          <use xlink:href="#rw-spin"></use>
        </svg>

        <!-- retry failed -->
        <template v-if="message.status === 'failed'">
          <span
            class="red-text"
            @click="tryAgain(message)"
            style="cursor: pointer"
            >retry</span
          >
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { waktu, cloudStorage, util } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";
import AppConfig from "@/common/config/app.config.json";
import { mapGetters } from "vuex";

export default {
  name: "ClientPart",
  mixins: [waktu, cloudStorage, loader, util],
  props: {
    message: {
      required: true,
    },
    clientImage: {
      required: true,
    },
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * for retry message failed
     * --------------------------------------------------------------
     */
    tryAgain: function (message) {
      message["status"] = "pending";
      this.$store
        .dispatch("customerSendMessage", message)
        .then(({ data }) => {
          // make sure cookies reset to null value
          let x = { rating: { uuid: null, endtime: null } };
          this.cookiesAdd(x); // js
          this.$store.dispatch("ConfigJs", x); // vuex

          // if token has been expired
          if (typeof data.data.token !== "undefined") {
            localStorage.setItem(
              AppConfig.rwStorage.customer.token,
              data.data.token
            );
          }
        })
        .catch(() => {
          this.getAllMessage.filter((all, index) => {
            if (all.uuid === message.uuid) {
              if (message.status === "pending") {
                message["status"] = "failed";
                this.$store.dispatch("MessageUpdate", {
                  index: index,
                  record: message,
                });
              }
            }
          });
        });
    },
  },
  computed: {
    ...mapGetters(["getAllMessage"]),
  },
};
</script>

<style scoped>
.icon {
  width: 12px;
  height: 25px;
  fill: #666666;
}
.icon-failed {
  fill: #e64964 !important;
  cursor: pointer;
}
.rw-chat-text {
  color: #666666;
  background: #cce5ff;
  border-color: #cce5ff;
}

.red-text {
  color: #dc3545 !important;
}
</style>
