<script>
import { clientMessage } from "@/common/mixins/mix_message";
import { loader } from "@/common/mixins/mix_loader";
import { clientCookies } from "@/common/mixins/mix_cookies";

export default {
  name: "CommonQuestion",
  mixins: [clientCookies, clientMessage, loader],
  data() {
    return {
      commonQuestions: [
        {
          text: "Cara Addons Domain",
          value: "addons",
        },
        {
          text: "Membuat website dalam 1 hari",
          value: "web1day",
        },
        {
          text: "Bagaimana cara login cPanel?",
          value: "logincPanel",
        },
        {
          text: "Pertanyaan Lainnya",
          value: "other",
        },
      ],
    };
  },
  methods: {
    async findAnswer(row = {}) {
      try {
        this.loading();
        await this.clientSendMessage(row.text, {
          tipe: row.value === "other" ? "form" : "text",
          actor: row.value === "other" ? "system" : "customer",
        });
        await this.cookiesSyncUpdate({ uuidCommonQuestion: null });
        this.loading(false);
      } catch (e) {
        location.reload();
      }
    },
  },
};
</script>

<template>
  <div class="flex flex-column gap-2">
    <button
      class="btn border-0 rw-bg-3 text-white rounded-pill w-full text-left"
      v-for="i in commonQuestions"
      :key="i.value"
      @click="findAnswer(i)"
    >
      <small v-html="i.text" />
    </button>
  </div>
</template>
