<script>
import { waktu } from "@/common/mixins/mix_helper";
export default {
  name: "BotBubble",
  mixins: [waktu],
  props: {
    message: {
      required: true,
    },
    showTime: {
      default: false,
    },
  },
  data() {
    return {
      botImage: "img/rw/bot.png",
    };
  },
};
</script>

<template>
  <div>
    <div class="float-left text-center">
      <img
        class="mt-2 h-8"
        :src="botImage"
        :alt="message.name"
        :title="message.name"
      />
      <div>
        <small>{{ message.name }}</small>
      </div>
    </div>
    <div class="rw-chat-text py-2">
      <slot></slot>

      <div class="float-right" v-if="showTime">
        <small>{{ mixEpochToHours(message.time) }}</small>
      </div>
      <div class="clear-both" />
    </div>
  </div>
</template>

<style scoped></style>
