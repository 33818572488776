<template>
  <div class="conversation">
    <icon-svg></icon-svg>
    <div
      class="flex w-full justify-center mt-2"
      v-if="getAllMessage.length >= limitShowMore && !isAllShow"
    >
      <div
        class="flex items-center rw-bg-3 text-white p-1 rounded"
        @click="btnLoadMoreMessage"
      >
        <span class="small">Load more message</span>
        <i class="material-symbols-outlined text-sm">refresh</i>
      </div>
    </div>

    <div
      v-for="(message, key) in getAllMessage"
      class="rw-chat"
      :class="message.actor === 'customer' ? 'rw-chat-right' : ''"
      :key="key"
    >
      <bot-part
        v-if="message.actor === 'system'"
        :message="message"
        :departmentReady="department"
      ></bot-part>

      <agent-part
        v-if="message.actor === 'staff'"
        :agentImage="image.staff"
        :message="message"
      ></agent-part>

      <client-part
        v-if="message.actor === 'customer' && message.tipe !== '1st-message'"
        :clientImage="image.customer"
        :message="message"
      ></client-part>
    </div>
    <!--    <br />-->

    <!--    <LiveChatAgent />-->
    <br />
    <ContinueChat />
  </div>
</template>

<script>
import "@/assets/css/customer/rw-chat.css";
import { image } from "@/common/config";
import { mapGetters } from "vuex";
import AppConfig from "@/common/config/app.config.json";
import { clientCookies } from "@/common/mixins/mix_cookies";
import { CookiesClient } from "@/common/config";

// component | widget
import botPart from "@/components/customer/body/conversation/BotPart";
import agentPart from "@/components/customer/body/conversation/AgentPart";
import clientPart from "@/components/customer/body/conversation/ClientPart";
import { waktu, request } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";
import IconSvg from "@/components/widget/IconSvg";
// import CommonQuestion from "@/components/customer/modules/CommonQuestion.vue";
// import LiveChatAgent from "@/components/customer/modules/LiveChatAgent.vue";
import ContinueChat from "@/components/customer/modules/ContinueChat.vue";

export default {
  name: "Conversation",
  mixins: [waktu, request, clientCookies, CookiesClient, loader],
  components: {
    ContinueChat,
    // LiveChatAgent,
    // CommonQuestion,
    IconSvg,
    clientPart,
    agentPart,
    botPart,
  },
  props: {
    /**
     * --------------------------------------------------------------
     * untuk memilih / indikator department mana saja yang sedang on
     * --------------------------------------------------------------
     */
    department: {
      required: true,
    },
  },
  data() {
    return {
      image: image,
      draw: 1,
      limitShowMore: AppConfig.limitMessageClient,
      isAllShow: false,
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * process request load more message from API
     * --------------------------------------------------------------
     */
    btnLoadMoreMessage() {
      let cookies = this.cookiesGet();
      if (cookies) {
        let params = {
          threadid: cookies.threadid,
          actor: "customer",
          limit: AppConfig.limitMessageClient,
          draw: this.getAllVuexAgent._drawMessage.value + 1,
          last_time: this.getAllVuexAgent._drawMessage.lastmsgid,
        };
        this.loading();
        this.API.post(this.URL.message.allMessage, params)
          .then(({ data }) => {
            if (data.data.record.length !== 0) {
              if (typeof data.data.record[0].time !== "undefined") {
                this.$store.dispatch("Draw", {
                  type: "setLastMessageID",
                  value: data.data.record[0].time,
                });
                this.$store.dispatch("Draw", { type: "increase" });
              }
              this.$store.dispatch("prependMessageCustomer", data.data.record);

              /**
               * --------------------------------------------------------------
               * jika ada token hasil re-generate
               * --------------------------------------------------------------
               */
              if (typeof data.data.token !== "undefined") {
                localStorage.setItem(
                  AppConfig.rwStorage.customer.token,
                  data.data.token
                );
              }
            } else {
              this.isAllShow = true;
              this.$swal({
                title: "",
                html: "All messages are displayed",
                icon: "success",
              });
            }
          })
          .catch((error) => {
            // ada manipulasi cookies tidak ada threadid
            let message = this.handleCatchAxios("All message", error, true);
            this.mixSwalBlock(message);
          })
          .finally(() => {
            this.loading(false);
          });
      } else {
        /**
         * --------------------------------------------------------------
         * clear cookies & localstorage & logout firebase & reload
         * --------------------------------------------------------------
         */
        this.$store
          .dispatch("FbAuthSignOut")
          .then(() => {
            this.cookiesRemove(); // make sure remove cookies
            localStorage.removeItem(AppConfig.rwStorage.customer.token);
            localStorage.removeItem(AppConfig.rwStorage.agent.config);
          })
          .finally(() => {
            location.reload();
          });
      }
    },
  },
  computed: {
    ...mapGetters(["getAllMessage", "getAllVuexAgent"]),
  },
  mounted() {
    if (this.getAllMessage.length === 0) {
      this.btnLoadMoreMessage();
    }
  },
};
</script>

<style scoped>
.conversation {
  line-height: 1.3;
  font-size: 14px;
}
.btn-load-more {
  cursor: pointer;
}
.btn-load-more:hover {
  background-color: #00aae5;
}
.icon-sync {
  width: 10px;
  height: 16px;
  fill: white;
}
/*.badge:hover > .icon-sync {*/
/*  fill: white;;*/
/*}*/
</style>

<style lang="scss">
svg.qc-icon {
  fill: #979797;
  height: 2rem;
  width: 2rem;
}
a:hover {
  text-decoration: none;
}
</style>
