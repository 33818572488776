<template>
  <div id="rw-content" class="flex flex-col h-screen">
    <!-- rw title -->
    <div class="rw-header">
      <cust-header :isLogin="getIsLogin"></cust-header>
    </div>

    <div class="rw-body overflow-hidden flex flex-col flex-grow">
      <div
        v-if="showSkeleton"
        class="d-flex justify-content-center my-5 flex-wrap"
      >
        <b-skeleton animation="wave" width="90%"></b-skeleton>
        <b-skeleton animation="wave" width="90%"></b-skeleton>

        <b-skeleton type="input" width="90%" class="my-2"></b-skeleton>
        <b-skeleton type="input" width="90%" class="my-2"></b-skeleton>
        <b-skeleton type="input" width="90%" class="my-2"></b-skeleton>
        <b-skeleton type="input" width="90%" class="my-2"></b-skeleton>
        <div style="width: 90%">
          <b-skeleton type="button" class="float-left"></b-skeleton>
        </div>
      </div>
      <cust-body :isLogin="getIsLogin" v-else></cust-body>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/css/customer/customer.css";
import { mapGetters } from "vuex";
import { clientCookies } from "@/common/mixins/mix_cookies";
import AppConfig from "@/common/config/app.config.json";
import { util } from "@/common/mixins/mix_helper";

// component children
import custHeader from "@/components/customer/header/HeaderBase";
import custBody from "@/components/customer/body/BodyBase";
Vue.use(BootstrapVue);

export default {
  name: "Customer",
  mixins: [clientCookies, util],
  components: {
    custHeader,
    custBody,
  },
  data() {
    return {
      showSkeleton: true,
    };
  },
  computed: {
    ...mapGetters(["getIsLogin"]),
  },
  created() {
    /**
     * --------------------------------------------------------------
     * hanya terjadi ketika refresh page
     * --------------------------------------------------------------
     */
    this.$store
      .dispatch("FbAuthIsLogin")
      .then(() => {
        let cookies = this.cookiesGet();
        if (typeof cookies !== "undefined") {
          this.$store.dispatch("customerLogin", true);
        } else {
          this.$store.dispatch("customerLogin", false);
          this.$store.dispatch("FbAuthSignOut");
        }
      })
      .catch(() => {
        /**
         * --------------------------------------------------------------
         * jika cookies masih ada tapi firebase logout maka dibuat
         * auto login kembali
         * --------------------------------------------------------------
         */
        let cookies = this.cookiesGet();
        let token = localStorage.getItem(AppConfig.rwStorage.customer.token);
        if (typeof cookies !== "undefined" && token !== null) {
          let jwt = this.jwtDecode(token);
          if (jwt) {
            let fbAuth = { email: jwt.fb.uname, password: jwt.fb.sandi };
            this.$store
              .dispatch("FbAuthLogin", fbAuth)
              .then(() => {
                this.$store.dispatch("customerLogin", true);
              })

              /**
               * --------------------------------------------------
               * gagal login firebase dibuat fallback interval
               * --------------------------------------------------
               */
              .catch((error) => {
                this.$store.dispatch("customerLogin", true);
                this.$store.dispatch("printDemo", `${error.message} (cC.A)`);
              });
          } else {
            console.error("Sorry, Your token is invalid!!");
          }
        } else {
          this.$store.dispatch("customerLogin", false);
        }
      })
      .finally(() => {
        setTimeout(() => {
          this.showSkeleton = false;
        }, 1000);
      });
  },
};
</script>
